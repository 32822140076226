<template>

  <a-row v-if="useSingleField" type="flex" justify="start" align="middle">
    <a-col :span="firstCol" class="input-bt">
      <input type="number" class="ant-input ant-input-lg "
             v-model="inputValue" :placeholder="placeholder"
             :min="1" :max="999" :step="1"/>
    </a-col>
  </a-row>
  <a-row v-else type="flex" justify="start" align="middle">
    <a-col  :xl="firstCol ? firstCol : (useForever ? 8 : 16)" :lg="24" :xs="24">
      <div :style="{ paddingRight: '0.5rem' }">
        <a-input v-if="foreverToggle" size="large" disabled value="∞"/>
        <input v-else type="number" class="ant-input ant-input-lg"
               :min="1" :max="999" :step="1"
               v-model="inputValue" :placeholder="placeholder"/>
      </div>
    </a-col>
    <a-col  :xl="useForever ? 16 : 8" :lg="24" :xs="24">
      <span class="text-nowrap" :style="{ marginRight: '1rem' }">{{ placeholderDays }}</span>
      <a-checkbox v-if="useForever" v-model:checked="isToggle" @click="toggleForever()">навсегда
      </a-checkbox>
    </a-col>
  </a-row>

</template>

<script>
import { nextTick } from "@vue/runtime-core";
import { computed } from "@vue/reactivity";

export default {
  name: 'SmartPeriodField',
  props: {
    modelValue: Number || String,
    foreverToggle: Boolean,
    useForever: Boolean,
    useSingleField: Boolean,
    firstCol: { type: Number },
    placeholder: { type: String, default: 'Число' },
    placeholderDays: { type: String, default: 'дней' },
  },
  emits: ['update:modelValue', 'update:foreverToggle', 'toggleChange', 'change'],
  setup(props, { emit }) {

    const inputValue = computed({
      get: () => props.modelValue,
      set: async (newValue) => {
        newValue = Math.ceil(+newValue);
        if (newValue < 1) newValue = 1;
        emit('update:modelValue', newValue);
        emit('change', newValue);
      }
    });

    const isToggle = computed({
      get: () => props.foreverToggle,
      set: async (newValue) => {
        nextTick(() => emit('update:foreverToggle', newValue));
      }
    });

    function toggleForever() {
      inputValue.value = isToggle.value ? 1 : -1;
      isToggle.value = !isToggle.value;
      nextTick(() => emit('toggleChange'));
    }

    return { inputValue, isToggle, toggleForever }
  },
}
</script>
<style lang="less">
.input-bt {
  @media (max-width: 1180px) {
      margin-bottom: 24px;
  }
}

</style>
