<template>
  <a-form
      class="offset-lg"
      :label-col="{ span: 6 }" :wrapper-col="{ span: 12, md: { span: 18 }, xl: { span: 12 } }"
      :model="form"
      :rules="rules"
      @submit.prevent="handleSubmit"
  >
    <a-form-item label="Название политики" v-bind="validateInfos.name" :style="{ marginBottom: '3rem' }" class="field-name">
      <a-input v-model:value="form.name" placeholder="Введите название"/>
    </a-form-item>
    <a-table :columns="columnsIntervals"
             :data-source="dataIntervals"
             :locale="{emptyText: 'Нет интервалов'}"
             :style="{ marginBottom: '1.5rem' }"
             rowKey='id'
             :pagination="false"
    >
      <template #intervals="{ record }">
        <a-form-item>
          <a-row type="flex" justify="start" align="middle">
            <a-col>
              <div :style="{ paddingRight: '0.5rem' }">
                <a-input @change="handleSelectInterval(record, $event)" size="large" type="number"
                         :min="0" :max="9999" :step="1"
                         v-model:value.number="form[record.input].time" placeholder="00"/>
              </div>
            </a-col>
            <a-col>минут</a-col>
          </a-row>
        </a-form-item>
      </template>
    </a-table>
    <!-- Интервалы бронирования -->
    <a-table :columns="columnsReservationDays"
             :data-source="dataReservationDays"
             rowKey='id'
             :pagination="false"
             class="tableCalendar"
    >
      <template #dayName="{ record }">
        <a-row :gutter="16" :key="'start_' + record.id">
          <a-col :span="14">
            <label :for="`day_chk_${record.id}`">
              {{ record.dayName }}
            </label>
          </a-col>
          <a-col :span="10">
            <a-checkbox v-model:checked="record.selected" :id="`day_chk_${record.id}`"
                        @change="onDayCheckboxChanged(record)"></a-checkbox>
          </a-col>
        </a-row>
      </template>
      <template #timeStart="{ record }">
        <a-row :gutter="16" :key="'start_' + record.id" :style="{ marginRight: '2rem' }">
          <a-col :span="24">
            <smart-time-field :key="'time_start_' + record.id" :input-id="'time_start_' + record.id"
                              v-model="record.timeStart" v-model:valid="record.timeStartValid"
                              @change="onTimeChanged('timeStart', record, $event)"/>
          </a-col>
        </a-row>
      </template>
      <template #timeEnd="{ record }">
        <a-row :gutter="16" :key="'end_' + record.id" :style="{ marginRight: '2rem' }">
          <a-col :span="24">
            <smart-time-field :key="'time_end_' + record.id" :input-id="'time_end_' + record.id"
                              v-model="record.timeEnd" v-model:valid="record.timeEndValid"
                              @change="onTimeChanged('timeEnd', record, $event)"/>
          </a-col>
        </a-row>
      </template>
    </a-table>
    <div class="form-footer">
      <a-button size="large" @click="handleReset">Отменить</a-button>
      <a-button type="primary" size="large" @click="handleSave" :loading="btnLoading"
                :disabled="!(isFormChanged('main') || isFormChanged('schedule'))">Сохранить
      </a-button>
    </div>

    <response-question-modal
        v-model:visible="isResponseQuestion" :message="responseQuestionMessage"
        :admin-message="responseQuestionAdminMessage"
        @proceed="handleResponseQuestionProceed"/>

  </a-form>

</template>

<script>
import { useForm } from '@ant-design-vue/use';
import { computed, ref, reactive, unref } from "@vue/reactivity";
import { notification } from "ant-design-vue";
import { useStore } from "vuex";
import { PUT_RESERVATION_POLICIES } from "@/store/politics";
import { onMounted, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import moment from 'moment';
import { getReservationPolitic } from "@/compositions/politics";
import { urlHelper, funcHelper } from "@/compositions/commonFunctions";
import { keepUnsavedChangesManager } from "@/compositions/keepUnsavedChangesManager";
import ResponseQuestionModal from "@/components/admin/politics/ResponseQuestionModal";
import SmartTimeField from "@/components/admin/shared/ul/SmartTimeField";

export default {
  components: { SmartTimeField, ResponseQuestionModal },
  props: {
    reservation: { type: Object, default: null },
    formChanged: { type: Boolean },
  },
  emits: ['update:formChanged'],
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {
      fetchData,
      dataReservationDays,
      columnsIntervals,
      columnsReservationDays,
      dataIntervals,
    } = getReservationPolitic();
    const { useKeepManager, fixChanges, isFormChanged, isOneOfFormsChanged } = keepUnsavedChangesManager();

    const btnLoading = ref(false);
    const isValid = ref(true);

    const isResponseQuestion = ref(false);
    const responseQuestionMessage = ref('');
    const responseQuestionAdminMessage = ref('');
    const responseQuestionPayload = ref({});

    watch(isOneOfFormsChanged, (val) => {
      emit('update:formChanged', val);
    });

    const form = reactive({
      name: '',
      intervalMinutes: {
        time: 0,
      },
      breakMinutes: {
        time: 0,
      },
      autoCancelMinutes: {
        time: 0,
      },
    });
    const rules = reactive({
      name: [
        { required: true, message: 'Пожалуйста, введите название', trigger: 'change' },
        { min: 1, max: 255, message: 'Длина должна быть от 1 до 255 символов', trigger: 'change' }
      ],
    });

    const schedule = ref(null)
    const isId = computed(() => route.params?.id);

    onMounted(async () => {
      await getReservation();
      useKeepManager({ 'main': form, 'schedule': dataReservationDays }, false, true);
    });

    // Расчет времени
    function minEndTimeAllowed(endOf) {
      return moment(endOf, 'DD.MM.YYYY HH:mm').add(-form.intervalMinutes.time || 0, 'minutes');
    }

    function isDatePairEnabled(startOf, endOf) {
      // Т.к. дату у нас подвешены в воздухе, и разница между 00:00 и 23:59 не очевидна,
      // это может быть вполне как тот же день, так и предыдущий.
      // Поэтому мы привязываем строки вида HH:mm к любой реальной дате, ну пускай это будет мой ДР.
      const start = moment(`14.01.1988 ${startOf}`, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm');
      const end = minEndTimeAllowed(`14.01.1988 ${endOf}`, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm');
      const isEarly = start <= end;
      // console.log(start, end, isEarly);
      return isEarly;
    }

    const { validate, validateInfos, resetFields } = useForm(form, rules);

    function handleSelectInterval(record, e) {
      if (e?.type === 'change') return;
      funcHelper.debounce(record, async () => {
        form[record.input].time = Math.max(0, Number.parseInt('' + form[record.input].time, 10));
        if (record.input === 'intervalMinutes') {
          notification.info({
            message: 'Интервал бронирования изменен',
            description: 'Скорректируйте доступные дни бронирования'
          });
        }
        validateTable();
      });
    }

    function onDayCheckboxChanged(checkbox) {
      dataReservationDays.forEach((item) => {
        // Вся неделя
        if (checkbox.id === 1001) {
          if (item.option === 'week') item.selected = false;
          if ([null, 'output'].includes(item.option)) item.selected = checkbox.selected;

          item.timeStart = null;
          item.timeEnd = null;
        }
        // Только будние дни
        if (checkbox.id === 1002) {
          if (['all', 'output'].includes(item.option)) item.selected = false;
          if (item.option === null) item.selected = checkbox.selected;

          item.timeStart = null;
          item.timeEnd = null;
        }

        // Мы выбрали какой-то из дней -- обнули пункт всей недели и будней
        if (![1001, 1002].includes(checkbox.id)) {
          if (['all', 'week'].includes(item.option)) {
            item.selected = false;
            item.timeStart = null;
            item.timeEnd = null;
          }
        }
      });

      const resetTime = dataReservationDays.find((item) => item.id === checkbox.id);
      // Если галочку убрали - сбросить времени
      if (!checkbox.selected) {
        resetTime.timeStart = null;
        resetTime.timeEnd = null;
      }
      validateTable();
    }

    // Выбор времени
    function onTimeChanged(field, record, value) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, item] of Object.entries(dataReservationDays)) {
        // Если введены оба времени - выдели день
        if (item.timeStart && item.timeEnd) {
          item.selected = true;
        }

        // Отмечаем все дни
        if (record.id === 1001) {
          if (item.option === 'week') item[field] = null;
          if ([null, 'output'].includes(item.option)) item[field] = record[field];
        }
        // Отмечаем все будние дни
        if (record.id === 1002) {
          if (['all', 'output'].includes(item.option)) item[field] = null;
          if (item.option === null) item[field] = record[field];
        }

        // Меняем время -- сбрось значения у галочек "все дни" + "будние дни"
        if (![1001, 1002].includes(record.id)) {
          if (['all', 'week'].includes(item.option)) {
            item.selected = false;
            item.timeStart = null;
            item.timeEnd = null;
          }
        }

        // Поставить фокус, если введено значение (а не стёрто)
        if (value) {
          let objectToFocus;
          if (field === 'timeStart') {
            objectToFocus = document.querySelector(`[id='time_end_${record.id}']`);
          }
          if (field === 'timeEnd') {
            objectToFocus = document.querySelector(`[id='time_start_${record.id + 1}']`);
          }
          objectToFocus?.focus();
        }
      }
      validateTable();
    }

    async function getReservation() {
      const reservation = props.reservation;
      if (reservation) {
        let isAllDaysSelected = reservation.schedule.length === 7;
        let isWorkDaysSelected = reservation.schedule.length === 5;
        let sameTimeStart = '';
        let sameTimeEnd = '';

        form.name = reservation.name;

        // Формируем бронирования
        dataReservationDays.forEach((item) => {

          const schedule = reservation.schedule.find((i) => i.day === item.day);
          if (schedule) {
            // Выбран или не выбран переключатель всех дней / рабочих дней
            if (!sameTimeStart) {
              sameTimeStart = schedule.timeFrom;
              sameTimeEnd = schedule.timeTo;
            } else {
              if (sameTimeStart !== schedule.timeFrom || sameTimeEnd !== schedule.timeTo) {
                isAllDaysSelected = false;
                isWorkDaysSelected = false;
              }
            }
            if (item.day > 5) {
              isWorkDaysSelected = false;
            }

            item.timeStart = schedule.timeFrom;
            item.timeEnd = schedule.timeTo;
            item.day = schedule.day;
            item.selected = schedule.enabled;
          }

        });
        // Параметры бронирований
        form.intervalMinutes.time = reservation.intervalMinutes || 0;
        form.breakMinutes.time = reservation.breakMinutes || 0;
        form.autoCancelMinutes.time = reservation.autoCancelMinutes || 0;

        // Проверяем, не отметить ли будни / все дни
        if (isAllDaysSelected) {
          dataReservationDays[0].selected = true;
          dataReservationDays[0].timeStart = dataReservationDays[2].timeStart;
          dataReservationDays[0].timeEnd = dataReservationDays[2].timeEnd;
        }

        if (isWorkDaysSelected) {
          dataReservationDays[1].selected = true;
          dataReservationDays[1].timeStart = dataReservationDays[2].timeStart;
          dataReservationDays[1].timeEnd = dataReservationDays[2].timeEnd;
        }
      }
    }

    // Валидация;
    function validateTable() {
      isValid.value = true;
      dataReservationDays.forEach((item) => {
        if (item.timeStart && item.timeEnd) {
          item.selected = true;
        }

        if (item.selected) {

          // Если выбран день, оба поля должны быть выбраны
          if (!item.timeStart) {
            item.timeStartValid = false;
            isValid.value = false;
          }
          if (!item.timeEnd) {
            item.timeEndValid = false;
            isValid.value = false;
          }
          // Если дата = 24:00, должно быть 23:59
          if (item.timeEnd === '24:00') {
            item.timeEnd = '23:59';
          }

          // Проверяем интервалы бронирования между выбранным временем
          if (!isDatePairEnabled(item.timeStart, item.timeEnd)) {
            isValid.value = false;
            item.timeStartValid = false;
            item.timeEndValid = false;
          } else {
            item.timeStartValid = true;
            item.timeEndValid = true;
          }

        } else {
          item.timeStartValid = true;
          item.timeEndValid = true;

          if (item.timeStart && !item.timeEnd) {
            item.timeEndValid = false;
            isValid.value = false;
          }
          if (item.timeEnd && !item.timeStart) {
            item.timeStartValid = false;
            isValid.value = false;
          }
        }

      });
      return isValid.value
    }

    // Сохранить изменения
    async function handleSubmit() {
      validate().then(async () => {
        btnLoading.value = true;
        const formData = {
          name: form.name,
          intervalMinutes: parseInt(form.intervalMinutes.time, 10),
          breakMinutes: parseInt(form.breakMinutes.time, 10),
          autoCancelMinutes: parseInt(form.autoCancelMinutes.time, 10),
          schedule: [],
        }
        // Формируем данные;
        for (const item of dataReservationDays) {
          if (item.selected && [null, 'output'].includes(item.option) // День недели, не Все дни или Будни
              && item.timeStart && item.timeEnd
          ) {
            formData.schedule.push({
              day: item.day,
              time_from: item.timeStart,
              time_to: item.timeEnd,
              enabled: true
            })
          }
        }

        await store.dispatch(PUT_RESERVATION_POLICIES, { id: isId.value, formData }).then(async (res) => {
          if (res?.id) {
            await handleSubmitResponse(res);
          } else {
            responseQuestionMessage.value = res?.message;
            responseQuestionAdminMessage.value = res?.messageAdmin;
            isResponseQuestion.value = true;
            responseQuestionPayload.value = { formData };
          }
        });

      }).catch(e => {
        console.log(e)
      }).finally(() => {
        btnLoading.value = false;
      });
    }

    async function handleResponseQuestionProceed(params) {
      const formData = unref(responseQuestionPayload)?.formData;
      const res = await store.dispatch(PUT_RESERVATION_POLICIES, { id: isId.value, formData, params })
      await handleSubmitResponse(res);
    }

    async function handleSubmitResponse(res) {
      if (res?.id) {
        notification.success({
          message: 'Успех',
          description: 'Изменения сохранены',
        });
        isResponseQuestion.value = false;
        // Новый заголовок
        urlHelper.setPageTitle(res?.name);
        await fetchData();
      } else {
        notification.error({ message: 'Ошибка сохранения', description: 'Попробуйте позже' });
      }
    }

    async function handleSave(e) {
      e.preventDefault();
      if (validateTable()) {
        await handleSubmit();
        fixChanges();
      } else {
        notification.error({
          message: 'Проверьте данные',
          description: 'В таблице есть пустые или некорректно заполненные поля',
        });
      }
    }

    // Отмена и выход из формы
    async function handleReset(e) {
      e.preventDefault();
      router.push({ name: 'politics' });
    }

    return {
      rules, btnLoading,
      form, validateInfos, resetFields,
      handleSubmit, handleSave, onDayCheckboxChanged, onTimeChanged, handleReset, handleSelectInterval,
      columnsIntervals, dataIntervals,
      columnsReservationDays, dataReservationDays,
      schedule,
      isValid, isFormChanged,
      isResponseQuestion, responseQuestionMessage, responseQuestionAdminMessage, handleResponseQuestionProceed,
    }
  }
}
</script>

<style lang="less">
.field-name .ant-form-item-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>