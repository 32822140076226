<template>

  <a-form
      class="offset-lg"
      :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }"
  >
    <nav class="ant-navbar text-right">
      <a-row type="flex" justify="space-between">
        <div>
          <p class="offset-sm">Укажите условия автоматической блокировки пользователей</p>
        </div>
        <div>
          <a-button size="large" type="dashed" @click="handleShowModalUsers">
            <template #icon>
              <plus-circle-outlined/>
            </template>
            Пользователь
          </a-button>
          <a-button size="large" type="dashed" @click="handleShowModalGroups">
            <template #icon>
              <plus-circle-outlined/>
            </template>
            Группа
          </a-button>
        </div>
      </a-row>
    </nav>

    <a-table
        key="tableFalseReservations"
        :scroll="{ x: 870}"
        class="tableUser"
        rowKey='id'
        :pagination="pagination"
        :columns="columnsFalseReservations"
        :data-source="dataFalseReservations"
        :locale="{
          emptyText: isFilterSelected ? 'Ничего не найдено' : 'Нет данных',
          filterConfirm: 'Применить',
          filterReset: 'Сбросить'
        }"
        @change="handleTableChange"
        :row-selection="{ selectedRowKeys: selectedCheckboxId, onChange: onSelectChange }"
        :loading="loading"
    >
      <!--Поиск-->
      <template #searchIcon>
        <search-outlined key="filter_search"
                         :style="{ color: selectedFilters.search ? '#E79B0A' : undefined }"/>
      </template>
      <template #filterDropdown="{ confirm, clearFilters }">
        <a-form @submit="handleSearch">
          <a-form-item>
            <a-input
                class="input"
                placeholder="Поиск"
                v-model:value="searchInput"
            />
          </a-form-item>
          <a-button class="btn-search" type="primary" @click="handleSearch(confirm)">
            <template #icon>
              <search-outlined/>
            </template>
            Поиск
          </a-button>
          <a-button class="btn-search" @click="handleReset(clearFilters)">Сброс</a-button>
        </a-form>
      </template>
      <!--/Поиск-->
      <template #entityName="{ record }">
        <span>{{ record.entityName || 'Без имени' }}</span>
      </template>
      <template #period="{ record }">
        <smart-period-field
            v-model.number="record.fictiveReservationPeriodDays"
            @change="handleUpdateRecord(record)"/>
      </template>
      <template #count="{ record }">
        <smart-period-field use-single-field
                            v-model.number="record.fictiveReservationCountThreshold" :style="{ maxWidth: '110px' }"
                            @change="handleUpdateRecord(record)" placeholder="—"/>
      </template>
      <template #block="{ record }">
        <smart-period-field
            v-model.number="record.blockPeriodDays" v-model:forever-toggle="record.blockPeriodDaysForever"
            @change="handleUpdateRecord(record)"
            use-forever/>
      </template>
      <template #action="{ record }">
        <close-outlined class="icons-svg close" @click="handleDelete(record)"/>
      </template>
      <template #actionTitle>
        <a-button size="small" :disabled="selectedCheckboxId.length === 0" @click="handleDeleteAll()">
          <delete-filled/>
        </a-button>
      </template>
    </a-table>
  </a-form>

  <!-- Пользователи -->
  <a-modal v-model:visible="showModalUser" title="Добавить пользователя" @ok="showModalUser = false" class="modalForm">
    <a-form
        ref="ruleFormUser"
        :model="formModal"
        layout="vertical"
        @submit.prevent="handleSubmitModal"
    >
      <a-form-item label="Пользователь *">
        <a-select
            show-search size="large"
            v-model:value="formModal.users.entityId"
            placeholder="Выберите пользователя"
            :loading="selectsUsers.loading"
            :default-active-first-option="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleUsersSearch"
        >
          <a-select-option v-for="user in selectsUsers.selects" :value="user.id" :key="user.id">{{ user.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Период *">
        <smart-period-field
            :first-col="8"
            v-model="formModal.users.fictiveReservationPeriodDays"
        />
      </a-form-item>
      <a-form-item label="Ложных бронирований *">
        <smart-period-field
            :first-col="8"
            use-single-field
            v-model="formModal.users.fictiveReservationCountThreshold"
        />
      </a-form-item>
      <a-form-item label="Блокировка *">
        <smart-period-field
            use-forever
            v-model="formModal.users.blockPeriodDays"
            v-model:forever-toggle="formModal.users.blockPeriodDaysForever"
        />
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button key="back" size="large" class="default" @click="showModalUser = false">Отменить</a-button>
      <a-button key="submit" size="large" :disabled="btnDisabledUser" class="btn-submit" type="primary"
                :loading="btnLoading" @click="handleSubmitModal">Добавить
      </a-button>
    </template>
  </a-modal>
  <!-- Группы -->
  <a-modal v-model:visible="showModalGroups" title="Добавить группу" @ok="showModalGroups = false" class="modalForm">
    <a-form
        ref="ruleFormUser"
        :model="formModal"
        layout="vertical"
        @submit.prevent="handleSubmitModal"
    >
      <a-form-item label="Группа *">
        <a-select
            show-search size="large"
            v-model:value="formModal.groups.entityId"
            placeholder="Выберите группу"
            :loading="selectsGroups.loading"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleGroupsSearch"
        >
          <a-select-option v-for="group in selectsGroups.selects" :value="group.id" :key="group.id">
            <span :class="{ strong: group.isMain }">{{ group.name }}</span>
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Период *">
        <smart-period-field
            :first-col="8"
            v-model="formModal.groups.fictiveReservationPeriodDays"
        />
      </a-form-item>
      <a-form-item label="Ложных бронирований *">
        <smart-period-field
            :first-col="8"
            use-single-field
            v-model="formModal.groups.fictiveReservationCountThreshold"
        />
      </a-form-item>
      <a-form-item label="Блокировка *">
        <smart-period-field
            use-forever
            v-model="formModal.groups.blockPeriodDays"
            v-model:forever-toggle="formModal.groups.blockPeriodDaysForever"
        />
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button size="large" key="back" class="default" @click="showModalGroups = false">Отменить</a-button>
      <a-button size="large" key="submit" :disabled="btnDisabledGroup" class="btn-submit" type="primary"
                :loading="btnLoading" @click="handleSubmitModal">Добавить
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { computed, reactive } from "vue";
import { ref, unref } from "@vue/reactivity";
import { message, Modal, notification } from "ant-design-vue";
import { useStore } from "vuex";
import { SearchOutlined, DeleteFilled } from '@ant-design/icons-vue';
import {
  DELETE_ALL,
  DELETE_RESERVATION_AUTO_BLOCK_POLICIES,
  POST_RESERVATION_AUTO_BLOCK_POLICIES, PUT_RESERVATION_AUTO_BLOCK_POLICIES,
  RESERVATION_AUTO_BLOCK_POLICIES
} from "@/store/politics";
import { onMounted } from "@vue/runtime-core";
import { PlusCircleOutlined, CloseOutlined } from '@ant-design/icons-vue';
import { getReservationPolitic } from "@/compositions/politics";
import { funcHelper } from "@/compositions/commonFunctions";
import SmartPeriodField from "../shared/ul/SmartPeriodField";

export default {

  setup() {
    const store = useStore();

    const loading = ref(false);

    const btnLoading = ref(false);

    const form = reactive({
      name: '',
      intervalMinutes: {
        time: '30',
        value: 1
      },
      breakMinutes: {
        time: '15',
        value: 1
      },
      autoCancelMinutes: {
        time: '10',
        value: 1
      },
    });

    const pagination = reactive({ hideOnSinglePage: true, pageSize: 999 });
    const selectedCheckboxId = ref([]);
    const selectBlockingPeriod = ref([]);
    const searchInput = ref('');
    const isFilterSelected = ref(false);

    const {
      isId,
      selectsUsers,
      fetchUsers,
      fetchGroups,
      selectsGroups,
      selectBlocking,
      handleUsersSearch,
      handleGroupsSearch
    } = getReservationPolitic()

    const selectedFilters = reactive({
      page: 1,
      search: '',
      block: [],
    });

    // Ложные бронирования
    const columnsFalseReservations = reactive([
      {
        title: 'Пользователь / Группа',
        dataIndex: 'name',
        key: 'name',
        width: '260px',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'searchIcon',
          customRender: 'entityName'
        },
        onFilter: (value, record) => record.entityName.toString().toLowerCase().includes(value.toLowerCase()),
      },
      {
        title: 'Период',
        slots: { customRender: 'period' },
      },
      {
        title: 'Ложных бронирований',
        slots: { customRender: 'count' },
      },
      {
        dataIndex: 'block',
        key: 'block',
        title: 'Блокировка',
        width: '300px',
        slots: { customRender: 'block' },
      },
      {
        fixed: 'right',
        width: '70px',
        slots: {
          customRender: 'action',
          title: 'actionTitle'
        },
      },
    ]);
    const dataFalseReservations = ref([]);

    const showModalUser = ref(false);
    const showModalGroups = ref(false);

    const btnDisabledUser = computed(() => {
      return !formModal.users.entityId
          || !formModal.users.fictiveReservationPeriodDays
          || !formModal.users.fictiveReservationCountThreshold
    })

    const btnDisabledGroup = computed(() => {
      return !formModal.groups.entityId
          || !formModal.groups.fictiveReservationPeriodDays
          || !formModal.groups.fictiveReservationCountThreshold
    })

    const formModalData = () => {
      return {
        users: {
          entityId: undefined,
          entityType: "user",
          blockPeriodMinutes: undefined,
          blockPeriodDays: 1, // Блокировка
          fictiveReservationPeriodMinutes: undefined,
          fictiveReservationPeriodDays: undefined, // Период
          fictiveReservationCountThreshold: undefined,
          blockPeriodDaysForever: false,
        },
        groups: {
          entityId: undefined,
          entityType: "group",
          blockPeriodMinutes: undefined,
          blockPeriodDays: 1,
          fictiveReservationPeriodMinutes: undefined,
          fictiveReservationPeriodDays: undefined,
          fictiveReservationCountThreshold: undefined,
          blockPeriodDaysForever: false,
        },
      }
    }

    // Форма users;
    const formModal = reactive({
      ...formModalData()
    });

    onMounted(async () => {
      // await nextTick()
      await fetchData()
      selectBlockingPeriod.value = selectBlocking.value.filter((item) => ![1004, 1005].includes(item.id));
    });

    async function fetchData() {
      const id = isId.value;
      try {
        loading.value = true;
        isFilterSelected.value = !!selectedFilters.search || selectedFilters.block.length > 0;
        const res = await store.dispatch(RESERVATION_AUTO_BLOCK_POLICIES, { id, params: unref(selectedFilters) });

        dataFalseReservations.value = res.data.map(item => {
          const blockPeriodDays = item.blockPeriodMinutes > 0 ? parseInt('' + item.blockPeriodMinutes / (60 * 24), 10) : -1;
          return {
            ...item,
            blockPeriodDays,
            fictiveReservationPeriodDays: parseInt(item.fictiveReservationPeriodMinutes / (60 * 24), 10),
            blockPeriodDaysForever: blockPeriodDays === -1,
          }
        });

        pagination.pageSize = +res.meta.perPage;
        pagination.total = +res.meta.total;
        pagination.current = +res.meta.currentPage;

        selectedFilters.page = pagination.current;

      } catch (e) {
        loading.value = false;
        throw e
      } finally {
        loading.value = false;
      }
    }

    // Добавить пользователя или группу
    const handleSubmitModal = async (e) => {
      e.preventDefault();

      formModal.user = formModal.group = null;
      showModalUser.value = showModalGroups.value = false;

      let formData = {}
      let messages = ''
      if (formModal.users.entityId) {
        formData = {
          ...formModal.users,
          fictiveReservationPeriodMinutes: formModal.users.fictiveReservationPeriodDays * 24 * 60,
          blockPeriodMinutes: formModal.users.blockPeriodDaysForever ? -1 : formModal.users.blockPeriodDays * 24 * 60,
        }
        messages = 'Пользователь успешно добавлен';
      }
      if (formModal.groups.entityId) {
        formData = {
          ...formModal.groups,
          fictiveReservationPeriodMinutes: formModal.groups.fictiveReservationPeriodDays * 24 * 60,
          blockPeriodMinutes: formModal.groups.blockPeriodDaysForever ? -1 : formModal.groups.blockPeriodDays * 24 * 60,
        }
        messages = 'Группа успешно добавлена';
      }
      const id = isId.value
      await store.dispatch(POST_RESERVATION_AUTO_BLOCK_POLICIES, { id, formData }).then(async (res) => {
        btnLoading.value = false;
        showModalUser.value = showModalGroups.value = false;
        if (res) {
          // message.success('Пользователь успешно добавлен');
          await fetchData().then(() => {
            notification.success({
              message: 'Успех',
              description: messages,
            });
          }).catch(() => {
          });
          resetForm();
        }
      })
    }

    async function handleUpdateRecord(record) {
      funcHelper.debounce(record, async () => {
        const id = record?.id;
        const body = dataFalseReservations.value.find((item) => item.id === id);
        // to Int
        body.fictiveReservationPeriodDays = Math.max(1, parseInt('' + body.fictiveReservationPeriodDays, 10));
        body.blockPeriodDays = parseInt('' + body.blockPeriodDays, 10);
        body.fictiveReservationCountThreshold = parseInt('' + body.fictiveReservationCountThreshold, 10);
        // to Infinity
        if (body.blockPeriodDays <= 0) {
          body.blockPeriodDays = -1;
          body.blockPeriodDaysForever = true;
        }
        const formData = {
          ...body,
          fictiveReservationPeriodMinutes: +body.fictiveReservationPeriodDays * 24 * 60,
          blockPeriodMinutes: body.blockPeriodDaysForever ? -1 : +body.blockPeriodDays * 24 * 60,
        };
        if (+formData.fictiveReservationCountThreshold <= 0) {
          return message.error('Количество ложных бронирований должно быть больше нуля');
        }
        await store.dispatch(PUT_RESERVATION_AUTO_BLOCK_POLICIES, {
          formData,
          id: isId.value,
          id2: id,
        }).then(async () => {
          notification.success({
            message: 'Успех',
            description: 'Изменения сохранены',
          });
        });
      });
    }

    const resetForm = () => {
      const { users, groups } = formModalData()
      formModal.users = users;
      formModal.groups = groups;
    }

    // Users Show Modal
    async function handleShowModalUsers() {
      showModalUser.value = true;
      resetForm();
      await fetchUsers();
    }

    // Groups Show Modal
    async function handleShowModalGroups() {
      showModalGroups.value = true;
      resetForm();
      await fetchGroups();
    }

    function handleDelete(record) {
      Modal.confirm({
        title: 'Удалить строку?',
        okText: 'Удалить',
        okType: 'danger',
        cancelText: 'Отмена',
        onOk() {
          const id = isId.value;
          store.dispatch(DELETE_RESERVATION_AUTO_BLOCK_POLICIES, { id, id2: record.id }).then(async (res) => {
            if (res) {
              await fetchData().then(() => {
                notification.success({
                  message: 'Успех',
                  description: 'Строка успешно удалена',
                });
              }).catch(() => {
              });
            }
          }).catch(() => {
          });
        },
        onCancel() {
        },
      });
    }

    function handleDeleteAll() {
      if (selectedCheckboxId.value.length) {
        Modal.confirm({
          title: 'Удалить строки?',
          content: `Выбрано (${selectedCheckboxId.value.length})`,
          okText: 'Удалить',
          okType: 'danger',
          cancelText: 'Отмена',
          onOk() {
            console.log('selectedCheckboxId.value.id', selectedCheckboxId.value)
            store.dispatch(DELETE_ALL, {
              ids: selectedCheckboxId.value,
              entityType: 'AutoBlockPolicy'
            }).then(async (res) => {
              console.log('DELETE_ALL', res)
              if (res) {
                await fetchData().then(() => {
                  notification.success({
                    message: 'Успех',
                    description: 'Строки успешно удалены',
                  });
                }).catch(() => {
                });
                selectedCheckboxId.value = [];
              }
            }).catch(() => {
            });
          },
          onCancel() {
          },
        });
      }
    }

    function onSelectChange(newSelectedRowKeys) {
      selectedCheckboxId.value = newSelectedRowKeys;
    }

    // Пагинация/фильтр/сортировка
    async function handleTableChange(pagination, filters) {
      selectedFilters.page = pagination.current;
      selectedFilters.block = filters.block || [];
      await fetchData();
    }

    // Поиск
    async function handleSearch() {
      selectedFilters.search = searchInput.value;
      await fetchData();
    }

    async function handleReset() {
      searchInput.value = '';
      selectedFilters.search = '';
      await fetchData();
    }

    return {
      loading,
      form,
      handleSubmitModal,
      handleShowModalUsers, handleShowModalGroups,
      fetchData,
      handleUpdateRecord, handleDelete, handleTableChange,
      onSelectChange, handleDeleteAll, handleSearch, handleReset,
      handleUsersSearch, handleGroupsSearch,
      columnsFalseReservations, dataFalseReservations,
      selectedCheckboxId,
      btnLoading,
      showModalUser, showModalGroups,
      selectsUsers, selectsGroups,
      formModal,
      selectBlocking, selectBlockingPeriod,
      btnDisabledUser, btnDisabledGroup,
      pagination, searchInput, selectedFilters, isFilterSelected,
    }
  },
  components: {
    SmartPeriodField,
    CloseOutlined, DeleteFilled, SearchOutlined, PlusCircleOutlined,
  },
}
</script>
<style lang="less">
.tableUser .ant-table-body {
  overflow: auto !important;
}
</style>
