<template>
  <nav class="ant-navbar text-right offset-lg">
    <a-row type="flex" justify="space-between">
      <div>
        <p class="offset-sm">Список заблокированных пользователей</p>
      </div>
      <div>
        <a-button size="large" type="dashed" @click="handleShowModal">
          <template #icon>
            <plus-circle-outlined/>
          </template>
          Пользователь
        </a-button>
        <a-button size="large" type="dashed" @click="handleShowModalGroups">
          <template #icon>
            <plus-circle-outlined/>
          </template>
          Группа
        </a-button>
      </div>
    </a-row>
  </nav>
  <a-table
      :scroll="{ x: 800}"
      class="tableUser"
      @change="handleTableChange"
      :pagination="pagination"
      :columns="columnsType" :data-source="dataTable"
      :locale="{emptyText: isFilterSelected ? 'Ничего не найдено' : 'Нет данных' }" :loading="loading"
      :row-selection="{ selectedRowKeys: selectedCheckboxId, onChange: onSelectChange }" rowKey='id'>
    <template #searchIcon>
      <search-outlined key="filter_search"
                       :style="{ color: selectedFilters.search ? '#E79B0A' : undefined }"/>
    </template>
    <template #filterDropdown="{ confirm, clearFilters }">
      <a-form @submit="handleSearch">
        <a-form-item>
          <a-input
              class="input"
              placeholder="Поиск"
              v-model:value="searchInput"
          />
        </a-form-item>
        <a-button class="btn-search" type="primary" @click="handleSearch(confirm)">
          <template #icon>
            <search-outlined/>
          </template>
          Поиск
        </a-button>
        <a-button class="btn-search" @click="handleReset(clearFilters)">Сброс</a-button>
      </a-form>
    </template>
    <template #entityName="{ record }">
      <span :class="{ strong: record.isMain }">{{ record.entityName || 'Без имени' }}</span>
    </template>
    <template #createdAt="{ text }">
      <span>{{ moment(text, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY') }}</span>
    </template>
    <template #blocking="{ record }">
      <a-row type="flex" justify="start" align="middle">
        <a-col :span="12">
          <div :style="{ paddingRight: '1rem' }">
            <a-input v-if="record.blockPeriodDaysForever" size="large" disabled value="∞"/>
            <a-date-picker v-else v-model:value="record.blockEnd" placeholder="Дата" size="large"
                           class="w-100" format="DD.MM.YY" value-format="YYYY-MM-DD"
                           :disabled-date="disabledEndDate" :allow-clear="false" :show-today="false"
                           @change="handleUpdateRecord(record, $event)"/>
          </div>
        </a-col>
        <a-col :span="12">
          <a-checkbox v-model:checked="record.blockPeriodDaysForever"
                      @click="toggleForever(record)">навсегда
          </a-checkbox>
        </a-col>
      </a-row>
    </template>
    <template #action="{ record }">
      <div class="action__com">
        <close-outlined class="icons-svg close" @click="handleDelete(record.id)"/>
      </div>
    </template>
    <template #actionTitle>
      <a-button size="small" :disabled="selectedCheckboxId.length === 0" @click="handleDeleteAll()">
        <delete-filled/>
      </a-button>
    </template>
  </a-table>
  <!-- Пользователи -->
  <a-modal v-model:visible="showModal" title="Добавить пользователя" @ok="showModal = false" class="modalForm">
    <a-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
        layout="vertical"
        @submit.prevent="handleSubmit"
    >

      <a-form-item label="Пользователь *" v-bind="validateInfos.entityId">
        <a-select show-search
                  size="large" v-model:value="form.entityId" placeholder="Выберите пользователя"
                  :loading="selectsUsers.loading"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handleUsersSearch"
        >
          <a-select-option v-for="user in selectsUsers.selects" :value="user.id" :disabled="user.disabled"
                           :key="user.id">{{ user.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Блокировка *">
        <a-row type="flex" justify="start" align="middle">
          <a-col :span="10">
            <a-input v-if="form.blockPeriodDaysForever" size="large" disabled value="∞"/>
            <a-date-picker v-else v-model:value="form.blockEnd" placeholder="Дата" size="large"
                           class="w-100" format="DD.MM.YY" value-format="YYYY-MM-DD"
                           :disabled-date="disabledEndDate" :allow-clear="false" :show-today="false"
                           @change="blockPeriodDaysChangeHandler(form)"/>
          </a-col>
          <a-col :span="14">&nbsp;&nbsp;
            <a-checkbox v-model:checked="form.blockPeriodDaysForever"
                        @click="toggleForever(form)">навсегда
            </a-checkbox>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button key="back" size="large" class="default" @click="showModal = false">Отменить</a-button>
      <a-button key="submit" size="large" type="primary" class="btn-submit" :loading="btnLoading"
                @click="handleSubmit($event, 'user')" :disabled="!form.entityId || !form.blockEnd">
        Добавить
      </a-button>
    </template>
  </a-modal>
  <!-- Группы -->
  <a-modal v-model:visible="showModalGroups" title="Добавить группу" @ok="showModalGroups = false" class="modalForm">
    <a-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
        layout="vertical"
        @submit.prevent="handleSubmit"
    >
      <a-form-item label="Группа *" v-bind="validateInfos.entityId">
        <a-select
            show-search size="large"
            v-model:value="form.entityId"
            placeholder="Выберите группу"
            :loading="selectsGroups.loading"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleGroupsSearch"
        >
          <a-select-option v-for="group in selectedGroupsFiltered" :value="group.id"
                           :disabled="group.disabled" :key="group.id">
            <span :class="{ strong: group.isMain }">{{ group.name }}</span>
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Блокировка *">
        <a-row type="flex" justify="start" align="middle">
          <a-col :span="10">
            <a-input v-if="form.blockPeriodDaysForever" size="large" disabled value="∞"/>
            <a-date-picker v-else v-model:value="form.blockEnd" placeholder="Дата" size="large"
                           class="w-100" format="DD.MM.YY" value-format="YYYY-MM-DD"
                           :disabled-date="disabledEndDate" :allow-clear="false" :show-today="false"
                           @change="blockPeriodDaysChangeHandler(form)"/>
          </a-col>
          <a-col :span="14">&nbsp;&nbsp;
            <a-checkbox v-model:checked="form.blockPeriodDaysForever"
                        @click="toggleForever(form)">навсегда
            </a-checkbox>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button key="back" size="large" class="default" @click="showModalGroups = false">Отменить</a-button>
      <a-button key="submit" size="large" type="primary" class="btn-submit" :loading="btnLoading"
                @click="handleSubmit($event, 'group')" :disabled="!form.entityId || !form.blockEnd">
        Добавить
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { reactive } from "vue";
import { computed, ref, unref } from "@vue/reactivity";
import { Modal, notification } from "ant-design-vue";
import { useStore } from "vuex";
import moment from 'moment';
import {
  DELETE_ALL,
  DELETE_RESERVATION_MANUAL_BLOCK_POLICIES,
  POST_RESERVATION_MANUAL_BLOCK_POLICIES,
  PUT_RESERVATION_MANUAL_BLOCK_POLICIES,
  RESERVATION_MANUAL_BLOCK_POLICIES
} from "@/store/politics";
import { onMounted } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { PlusCircleOutlined, CloseOutlined, SearchOutlined, DeleteFilled } from '@ant-design/icons-vue';
import { useForm } from "@ant-design-vue/use";
import { getReservationPolitic } from "@/compositions/politics";

export default {

  setup() {
    const store = useStore();
    const route = useRoute();

    const showModal = ref(false);
    const showModalGroups = ref(false);

    const loading = ref(false);
    const btnLoading = ref(false);
    const pagination = reactive({ hideOnSinglePage: true, pageSize: 999 });
    const selectedCheckboxId = ref([]);

    const selectedFilters = reactive({
      page: 1,
      search: '',
      sortCreateBy: 'ascend',
    });

    const columnsType = ref([
      {
        title: 'Пользователь / Группа',
        width: '260px',
        dataIndex: 'name',
        key: 'name',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'searchIcon',
          customRender: 'entityName'
        },
        onFilter: (value, record) => record.entityName.toString().toLowerCase().includes(value.toLowerCase()),
      },
      {
        width: '160px',
        title: 'Начало блокировки',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        defaultSortOrder: 'ascend',
        slots: { customRender: 'createdAt' },
      },
      {
        title: 'Окончание блокировки',
        dataIndex: 'blocking',
        key: 'blocking',
        width: '300px',
        slots: { customRender: 'blocking' },
      },
      {
        fixed: 'right',
        slots: {
          customRender: 'action',
          title: 'actionTitle'
        },
        //scopedSlots: { customRender: 'action' },
      },
    ]);
    const dataTable = ref([]);
    const isFilterSelected = ref(false);

    const {
      fetchUsers,
      fetchGroups,
      selectsGroups,
      selectsUsers,
      handleUsersSearch,
      handleGroupsSearch
    } = getReservationPolitic();

    const selectedGroupsFiltered = computed(() => (selectsGroups.selects.filter((group) => !group.isMain)));
    const searchInput = ref('');
    const form = reactive({
      entityId: undefined,
      blockEnd: moment().add(1, 'day'),
      blockPeriodDaysForever: false,
    });
    const rules = reactive({
      entityId: [
        { required: true, message: 'Поле не может быть пустым', trigger: 'change' },
      ],
    });
    const { validate, validateInfos, resetFields } = useForm(form, rules);

    onMounted(async () => {
      await fetchData()
    });

    async function fetchData() {
      const id = route.params?.id;
      try {
        loading.value = true;
        isFilterSelected.value = !!selectedFilters.search;
        const res = await store.dispatch(RESERVATION_MANUAL_BLOCK_POLICIES, { id, params: unref(selectedFilters) });
        dataTable.value = res.data.map(item => ({
          ...item,
          blockPeriodDays: item.blockPeriodMinutes > 0 ? parseInt('' + item.blockPeriodMinutes / (60 * 24), 10) : -1,
          blockPeriodDaysForever: item.blockPeriodMinutes <= 0,
          blockEnd: moment(item.blockExpiredAt),
        }))

        pagination.pageSize = +res.meta.perPage;
        pagination.total = +res.meta.total;
        pagination.current = +res.meta.currentPage;

        selectedFilters.page = pagination.current;
      } finally {
        loading.value = false;
      }
    }

    function onSelectChange(newSelectedRowKeys) {
      selectedCheckboxId.value = newSelectedRowKeys;
    }

    async function handleShowModal() {
      resetFields();
      showModal.value = true;
      await fetchUsers();
    }

    // Groups Show Modal
    async function handleShowModalGroups() {
      resetFields();
      showModalGroups.value = true;
      await fetchGroups();
    }

    const handleSubmit = (e, type) => {
      e.preventDefault();
      validate().then(async () => {
        btnLoading.value = true;
        const id = route.params?.id;
        const duration = moment.duration(moment(form.blockEnd).diff(moment()));
        const minutes = Math.ceil(duration.asMinutes());

        const formData = {
          id,
          entityType: type, // user || group
          entityId: form.entityId,
          blockPeriodMinutes: form.blockPeriodDaysForever ? -1 : minutes,
        }
        await store.dispatch(POST_RESERVATION_MANUAL_BLOCK_POLICIES, { formData }).then(async (res) => {
          btnLoading.value = showModal.value = false;
          showModalGroups.value = false;
          if (res) {
            let messages = ''
            if (type === 'user') {
              messages = 'Пользователь успешно добавлен';
            }
            if (type === 'group') {
              messages = 'Группа успешно добавлена';
            }
            await fetchData().then(() => {
              notification.success({
                message: 'Успех',
                description: messages,
              });
            }).catch(() => {
            });
          }
        })
      }).catch(e => {
        console.log('catch', e)
        btnLoading.value = false;
      });
    }

    function handleDeleteAll() {
      if (selectedCheckboxId.value.length) {
        Modal.confirm({
          title: 'Удалить строки?',
          content: `Выбрано (${selectedCheckboxId.value.length})`,
          okText: 'Удалить',
          okType: 'danger',
          cancelText: 'Отмена',
          onOk() {
            console.log('onSelectChange', selectedCheckboxId.value)
            store.dispatch(DELETE_ALL, {
              ids: selectedCheckboxId.value,
              entityType: 'ManualBlockPolicy'
            }).then(async (res) => {
              console.log(res)
              selectedCheckboxId.value = [];
              if (res) {
                await fetchData().then(() => {
                  notification.success({
                    message: 'Успех',
                    description: 'Строки успешно удалены',
                  });
                }).catch(() => {
                });
              }

            }).catch(() => {
            });
          },
          onCancel() {
          },
        });
      }
    }

    function handleDelete(id2) {
      Modal.confirm({
        title: 'Удалить строку?',
        okText: 'Удалить',
        okType: 'danger',
        cancelText: 'Отмена',
        onOk() {
          const id = route.params?.id;
          store.dispatch(DELETE_RESERVATION_MANUAL_BLOCK_POLICIES, { id, id2 }).then(async (res) => {
            if (res) {
              await fetchData().then(() => {
                notification.success({
                  message: 'Успех',
                  description: 'Строка успешно удалена',
                });
              }).catch(() => {
              });
            }
          }).catch(() => {
          });
        },
        onCancel() {
        },
      });
    }

    // Пагинация/фильтр/сортировка
    async function handleTableChange(pagination, filters, sorter) {
      selectedFilters.page = pagination.current;
      selectedFilters.sortCreateBy = sorter.order || 'ascend';
      await fetchData()
    }

    async function handleSearch() {
      selectedFilters.search = searchInput.value || '';
      await fetchData();
    }

    async function handleReset() {
      searchInput.value = '';
      selectedFilters.search = '';
      await fetchData();
    }

    function disabledEndDate(current) {
      return current && current < moment().endOf('day');
    }

    // Обновить строку
    async function handleUpdateRecord(record, e) {
      if (e?.type === 'change') return; // Только input обрабатывает, change это у Ant по сути blur
      blockPeriodDaysChangeHandler(record); // Проверить что введенная дата не навсегда
      const id = route.params?.id;

      // Разница в минутах между двумя датами (создания и конечной датой)
      const duration = moment.duration(moment(record.blockEnd).diff(record.createdAt));
      const minutes = Math.ceil(duration.asMinutes());

      const formData = {
        entityType: record.entityType,
        entityId: record.entityId,
        blockPeriodMinutes: record.blockPeriodDaysForever ? -1 : minutes,
      }
      await store.dispatch(PUT_RESERVATION_MANUAL_BLOCK_POLICIES, {
        id,
        id2: record.id,
        formData
      }).then(async (res) => {
        if (res) {
          await fetchData().then(() => {
            notification.success({
              message: 'Успех',
              description: 'Изменения сохранены',
            });
          }).catch(() => {
          })
        }
      }).catch(() => {
      })
    }

    // formData = форма или запись record
    function toggleForever(formData) {
      formData.blockEnd = formData.blockPeriodDaysForever ? moment(formData.createdAt).add(1, 'day') : -1;
      formData.blockPeriodDaysForever = !formData.blockPeriodDaysForever;
      if (formData?.id) {
        handleUpdateRecord(formData);
      }
    }

    function blockPeriodDaysChangeHandler(formData) {
      if (!formData.blockEnd) {
        formData.blockPeriodDays = -1;
        formData.blockPeriodDaysForever = true;
      }
    }

    return {
      moment, loading, btnLoading,
      fetchData, isFilterSelected,
      onSelectChange, handleTableChange,
      handleDeleteAll, handleDelete,
      handleShowModal,
      handleSubmit, handleUpdateRecord,
      handleSearch, handleReset,
      handleShowModalGroups, handleGroupsSearch, selectedGroupsFiltered,
      handleUsersSearch, showModal,
      showModalGroups, pagination,
      columnsType, dataTable, selectedCheckboxId, selectedFilters,
      rules, resetFields,
      validateInfos,
      toggleForever, blockPeriodDaysChangeHandler, disabledEndDate,
      form,
      selectsUsers, selectsGroups,
      searchInput
    }
  },
  components: { CloseOutlined, DeleteFilled, PlusCircleOutlined, SearchOutlined, },
}
</script>
