<template>
  <a-modal v-model:visible="isVisible" title="Обратите внимание" @ok="isVisible = false">

    <p>{{ message }}</p>

    <a-form layout="vertical" v-if="adminMessage">
      <a-form-item>
        <a-checkbox v-model:checked="withAdmin">{{ adminMessage }}</a-checkbox>
      </a-form-item>
    </a-form>

    <template #footer>
      <footer class="ant-modal-confirm-btns">
        <a-button key="back" size="large" @click="handleSubmit(false)">Нет</a-button>
        <a-button key="submit" size="large" type="primary" @click="handleSubmit(true)">Да</a-button>
      </footer>
    </template>

  </a-modal>
</template>

<script>

import { computed, ref, unref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

export default {
  name: 'ResponseQuestionModal',
  props: {
    visible: Boolean,
    message: String,
    adminMessage: { type: String, default: 'С учетом админских бронирований' },
    proceedParam: { type: String, default: 'override' },
  },
  emits: ['proceed', 'update:visible'],
  setup(props, { emit }) {
    const isVisible = computed({
      get: () => props.visible,
      set: (value) => emit('update:visible', value)
    })

    const withAdmin = ref(false);

    watch(isVisible, (open, close) => {
      if (open && !close) {
        withAdmin.value = false;
      }
    });

    function handleSubmit(answer) {
      const payload = {};
      payload[props.proceedParam] = answer;
      if (props.adminMessage) {
        payload.withAdmin = unref(withAdmin);
      }
      emit('proceed', payload);
    }

    return {
      isVisible, withAdmin, handleSubmit
    }
  },
}
</script>
