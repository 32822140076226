<template>
  <a-table :columns="columnsNotifications" :data-source="dataNotifications" class="offset-lg"
           :pagination="{ hideOnSinglePage: true, }"
           rowKey="id"
  >
    <template #confirmation="{ record }">
      <div class="check" v-if="record.approveShow">
        <a-checkbox v-model:checked="record.approveEmail"></a-checkbox>
      </div>
    </template>
    <template #notificationBefore="{ record }">
      <a-form-item>
        <a-row :gutter="16" type="flex" justify="start" align="middle" :style="{ marginRight: '2rem' }"
               v-if="record.timeShow">
          <a-col>
            <a-input @blur="validateTable" size="large" type="number"
                     class="w-100"
                     :min="0" :max="9999" :step="1"
                     v-model:value.number="record.firstRemindIntervalMinutes" placeholder="00"/>
          </a-col>
          <a-col>минут</a-col>
        </a-row>
      </a-form-item>
    </template>
    <template #notificationRepeat="{ record }">
      <a-form-item :disabled="!record.firstRemindIntervalMinutes">
        <a-row :gutter="16" type="flex" justify="start" align="middle" :style="{ marginRight: '2rem' }"
               v-if="record.timeShow">
          <a-col>
            <a-input @blur="validateTable" size="large" type="number"
                     class="w-100"
                     :class="{ 'has-error': +record.firstRemindIntervalMinutes > 0 && +record.firstRemindIntervalMinutes <= +record.secondRemindIntervalMinutes }"
                     :min="0" :max="9999"
                     v-model:value="record.secondRemindIntervalMinutes" placeholder="00"/>

          </a-col>
          <a-col>минут</a-col>
        </a-row>
      </a-form-item>
    </template>
  </a-table>
  <div class="form-footer">
    <a-button size="large" @click="handleReset">Отменить</a-button>
    <a-button :loading="btnLoading" type="primary" size="large" @click="handleSave" :disabled="!isOneOfFormsChanged">
      Сохранить
    </a-button>
  </div>
</template>

<script>
import { reactive } from "vue";
import { ref } from "@vue/reactivity";
import { message, notification } from "ant-design-vue";
import { useStore } from "vuex";
import { PUT_RESERVATION_POLICIES } from "@/store/politics";
import { onMounted, watch } from "@vue/runtime-core";
import { getReservationPolitic } from "@/compositions/politics";
import { useRouter } from "vue-router";
import { keepUnsavedChangesManager } from "@/compositions/keepUnsavedChangesManager";

export default {
  props: {
    reservation: { type: Object, default: null },
    formChanged: { type: Boolean },
  },
  emits: ['update:formChanged'],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const loading = ref(false);
    const btnLoading = ref(false);

    const { isId, fetchData } = getReservationPolitic();
    const { useKeepManager, fixChanges, isOneOfFormsChanged } = keepUnsavedChangesManager();

    watch(isOneOfFormsChanged, (val) => {
      emit('update:formChanged', val);
    });

    // Уведомления
    const columnsNotifications = [
      {
        title: 'Наименование',
        dataIndex: 'name',
        key: 'name',
        width: '25%'
      },
      {
        title: 'Подтверждение',
        slots: { customRender: 'confirmation' },
      },
      {
        title: 'Напоминание (до начала)',
        slots: { customRender: 'notificationBefore' },
      },
      {
        title: 'Повторное напоминание',
        slots: { customRender: 'notificationRepeat' },
      },
    ];
    const dataNotifications = reactive([
      {
        id: 1001,
        key: 0,
        name: 'Место забронировано',
        eventType: 'on_reserve',

        approveEmail: false,
        firstRemindIntervalMinutes: 0,
        secondRemindIntervalMinutes: 0,

        timeShow: false,
        approveShow: true,
      },
      {
        id: 1002,
        key: 1,
        name: 'Отмена бронирования',
        eventType: 'on_reserve_cancel',

        approveEmail: false,
        firstRemindIntervalMinutes: 0,
        secondRemindIntervalMinutes: 0,

        timeShow: false,
        approveShow: true,
      },
      {
        id: 1003,
        key: 2,
        name: 'Автоматический сброс бронирования',
        eventType: 'on_reserve_auto_cancel',

        approveEmail: false,
        firstRemindIntervalMinutes: 0,
        secondRemindIntervalMinutes: 0,

        timeShow: true,
        approveShow: true,
      },
      {
        id: 1004,
        key: 3,
        name: 'Начало бронирования',
        eventType: 'on_reserve_start',

        firstRemindIntervalMinutes: 0,
        secondRemindIntervalMinutes: 0,

        timeShow: true,
        approveShow: false,
      },
      {
        id: 1005,
        key: 4,
        name: 'Конец бронирования',
        eventType: 'on_reserve_end',

        firstRemindIntervalMinutes: 0,
        secondRemindIntervalMinutes: 0,

        timeShow: true,
        approveShow: false,
      },
    ]);

    onMounted(async () => {
      await getNotifications();
      useKeepManager({ 'notifications': dataNotifications }, false, true);
    });

    function getNotifications() {
      if (props.reservation && props.reservation.notifications.length) {
        // Формируем брони;
        props.reservation.notifications.forEach((item, index) => {
          const notifications = dataNotifications.find((i) => i.key === index);

          notifications.firstRemindIntervalMinutes = item.firstRemindIntervalMinutes || 0;
          notifications.secondRemindIntervalMinutes = notifications.firstRemindIntervalMinutes ? item.secondRemindIntervalMinutes || 0 : 0;
          notifications.approveEmail = item.approveEmail;
        })
      }
    }

    // Валидация;
    const validateTable = () => {
      let isValid = true;
      for (const item of dataNotifications) {
        item.firstRemindIntervalMinutes = parseInt('' + item.firstRemindIntervalMinutes, 10) || 0;
        item.secondRemindIntervalMinutes = parseInt('' + item.secondRemindIntervalMinutes, 10) || 0;

        if (item.firstRemindIntervalMinutes < 0) {
          item.firstRemindIntervalMinutes = 0;
          message.info('Время не должно быть меньше нуля');
        }
        if (item.secondRemindIntervalMinutes < 0) {
          item.secondRemindIntervalMinutes = 0;
          message.info('Время повторного напоминания не должно быть меньше нуля');
        }
        if (item.firstRemindIntervalMinutes === 0) {
          item.secondRemindIntervalMinutes = 0;
        }

        //  Проверяем Повторное напоминание
        if (item.firstRemindIntervalMinutes && item.secondRemindIntervalMinutes && +item.firstRemindIntervalMinutes <= +item.secondRemindIntervalMinutes) {
          isValid = false;
        }
      }
      return isValid;
    }

    const handleSave = e => {
      e.preventDefault();
      if (validateTable()) {
        handleSubmit()
      } else {
        notification.error({
          message: 'Проверьте данные',
          description: 'В таблице есть пустые или некорректно заполненные поля',
        });
      }
      return false;
    }

    // Сохранить изменение;
    const handleSubmit = async () => {
      const formData = {
        notifications: [],
      }

      dataNotifications.forEach((item, index) => {
        formData.notifications[index] = {
          event: item.eventType,
          name: item.name,
          firstRemindIntervalMinutes: item.firstRemindIntervalMinutes,
          secondRemindIntervalMinutes: item.secondRemindIntervalMinutes,
          approveEmail: item.approveEmail,
        }
      })
      console.log('formData', formData);
      await store.dispatch(PUT_RESERVATION_POLICIES, { formData, id: isId.value }).then(async (res) => {
        if (res) {
          notification.success({
            message: 'Успех',
            description: 'Изменения сохранены',
          });
          fixChanges();
          btnLoading.value = false;
        }
      }).catch(() => {
        btnLoading.value = false;
      })
    }

    async function handleReset(e) {
      e.preventDefault();
      router.push({ name: 'politics' });
    }

    return {
      loading, btnLoading,
      handleSubmit, handleSave,
      handleReset, validateTable,
      fetchData,
      columnsNotifications,
      dataNotifications,
      isOneOfFormsChanged
    }
  }
}
</script>

<style scoped lang="less">

</style>
