<template>

  <section>
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{ name: 'politics' }">Политика доступов</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ pageTitle }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-page-header :title="pageTitle" @back="() => $router.push({name: 'politics'})"
                   :class="{ loading }">
      <template #extra>
        <a-button key="delete" size="large" @click="handleDelete">
          <template #icon>
            <delete-outlined/>
          </template>
        </a-button>
      </template>
    </a-page-header>

    <a-skeleton :loading="loading">
      <main>
        <a-tabs v-model:active-key="currentTab">

          <a-tab-pane key="info" tab="Основное">
            <app-tab-content-main
                :reservation="reservation"
                v-model:form-changed="formChanges.info"/>
          </a-tab-pane>

          <a-tab-pane key="notifications" tab="Уведомления">
            <app-tab-content-notifications
                :reservation="reservation"
                v-model:form-changed="formChanges.notifications"/>
          </a-tab-pane>

          <a-tab-pane key="false_reservations" tab="Ложные бронирования">
            <app-tab-content-false-reservations/>
          </a-tab-pane>

          <a-tab-pane key="block" tab="Блокировка пользователей">
            <app-tab-content-block-users/>
          </a-tab-pane>

          <a-tab-pane key="white" tab="Белый список">
            <app-tab-content-white-list/>
          </a-tab-pane>
        </a-tabs>
      </main>
    </a-skeleton>
  </section>

</template>

<script>
import { onMounted, watch } from "@vue/runtime-core";
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import AppTabContentMain from "@/components/admin/politics/TabContentMain";
import AppTabContentNotifications from "@/components/admin/politics/TabContentNotifications";
import AppTabContentFalseReservations from "@/components/admin/politics/TabContentFalseReservations";
import AppTabContentBlockUsers from "@/components/admin/politics/TabContentBlockUsers";
import AppTabContentWhiteList from "@/components/admin/politics/TabContentWhiteList";
import { getReservationPolitic } from "@/compositions/politics";
import { keepUnsavedChangesManager } from "@/compositions/keepUnsavedChangesManager";
import { DELETE_RESERVATION_POLICIES, GET_RESERVATION_POLITIC } from "@/store/politics";
import { urlHelper } from "@/compositions/commonFunctions";
import {
  DeleteOutlined,
} from '@ant-design/icons-vue';
import { Modal, notification } from "ant-design-vue";
import { reactive } from "vue";
import { DELETE_RESERVATION_POLICIES_FORCE } from "@/store/politics";

export default {

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const pageTitle = ref('Загрузка...');
    const { fetchData, loading } = getReservationPolitic()
    const reservation = computed(() => store.getters[GET_RESERVATION_POLITIC]);
    const currentTab = ref('info');

    const formChanges = reactive({ info: false, notifications: false });
    const { useKeepManager, fixChanges } = keepUnsavedChangesManager();

    onMounted(async () => {
      await fetchData();
      pageTitle.value = reservation.value?.name;
      urlHelper.setPageTitle(pageTitle.value);
      useKeepManager(formChanges, handleKeepChanges);

      // Открыть нужный таб
      if (route.hash) {
        const hash = route.hash.substr(1);
        if (['info', 'notifications', 'false_reservations', 'block', 'white'].indexOf(hash) >= 0) currentTab.value = hash;
      }
    });

    watch(reservation, (newVal) => {
      if (newVal) {
        pageTitle.value = newVal?.name;
        urlHelper.setPageTitle(pageTitle.value);
      }
    })

    watch(currentTab, (tab) => {
      window.location.hash = tab;
    });

    function handleDelete() {
      Modal.confirm({
        title: 'Удалить политику доступа?',
        okText: 'Удалить',
        okType: 'danger',
        cancelText: 'Отмена',
        onOk() {
          store.dispatch(DELETE_RESERVATION_POLICIES, route.params?.id).then(async (res) => {
            if (res.id) {
              fixChanges();
              notification.success({
                message: 'Успех',
                description: 'Запись успешно удалена',
              });
              router.push({ name: 'politics' });
            } else {
              if (res.errorCode === 'only_force_delete') {
                Modal.confirm({
                  title: 'Эта политика привязана к объекту. Всё равно удалить?',
                  okText: 'Да, удалить',
                  okType: 'danger',
                  cancelText: 'Нет',
                  onOk() {
                    store.dispatch(DELETE_RESERVATION_POLICIES_FORCE, route.params?.id).then(() => {
                      fixChanges();
                      notification.success({
                        message: 'Успех',
                        description: 'Запись успешно удалена',
                      });
                      router.push({ name: 'politics' })
                    });
                  },
                });
              } else {
                Modal.info({
                  title: 'Удаление невозможно',
                  content: 'Невозможно удалить политику. Эта политика привязана к типу объекта',
                  okText: 'Отменить',
                  okType: 'default',
                });
              }
            }
          }).catch((e) => {
            console.error(e);
          });
        },
      });
    }

    function handleKeepChanges(changedForms) {
      if (changedForms.length > 0) currentTab.value = changedForms[0];
    }

    return {
      pageTitle, loading, currentTab,
      reservation,
      handleDelete, formChanges,
    }
  },
  components: {
    AppTabContentMain,
    AppTabContentNotifications,
    AppTabContentFalseReservations,
    AppTabContentBlockUsers,
    AppTabContentWhiteList,
    DeleteOutlined,
  }
}
</script>


<style lang="less" scoped>

</style>
